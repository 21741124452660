<template>
  <div id="app">
    <br>
    <br>
    <br>
    <div id="first-block">

      <div
        id="first-block-description"
      >  
        <div>
          <h1> ВЫСОКОСКОРОСТНОЕ ОБОРУДОВАНИЕ</h1>
          <div>
            Использование радиорелейных каналов связи оправдано там, где нужны большие скорости на больших расстояниях. Мы используем оборудование Ericsson MINI-LINK PT2010 которое позволяет организовать линию связи со скоростью до 500 мб/с. Mini-Link PT 2010 поддерживает оптические интерфейсы с использованием SFP модулей и работает в режимах 1+0, 1+1, 2+0. Антенна диаметром 0.3 или 0.6 метра компактна и обеспечивает надежную работу Частотный диапазон - 13 и 23 Ггц. Дальность связи до - 19 км.
          </div>
        </div>


      </div>
    </div>
      <br>
    <div id="second-block">
      <div class="title-block">
        ⦾ Почему мы
      </div>
      <br>
      <br>
      <table style="text-align: center; width: 1280px; text-align: center; fo">
        <tr>
          <td style="width: 150px; padding: auto; ">
            <img src="../public/img/ericsson.svg" style="height: 120px;" >
          </td>
          <td style="width: 150px; padding: auto;">
            <img src="../public/img/warranty.svg" class="preg">
          </td>
          <td style="width: 150px; padding: auto;">
            <img src="../public/img/license.svg" class="preg">
          </td>
          <td style="width: 150px; padding: auto;">
            <img src="../public/img/team.svg" class="preg">
          </td>
          <td style="width: 150px; padding: auto;">
            <img src="../public/img/clock.svg" class="preg">
          </td>
          <td style="width: 150px; padding: auto;">
            <img src="../public/img/task.svg"  class="preg">
          </td>
        </tr>
        <tr>
          <td style="vertical-align: top;max-width: 100px;"><b>Сертификат Ericsson</b></td>
          <td style="vertical-align: top;max-width: 100px;"><b>Гарантийное и постгарантийное обслуживание</b></td>
          <td style="vertical-align: top;max-width: 100px;"><b>Лицензии на все виды работ</b></td>
          <td style="vertical-align: top;max-width: 100px;"><b>Команда опытных и квалифицированных профессионалов</b></td>
          <td style="vertical-align: top;max-width: 100px;"><b>Передовые технологии, <br> современное оборудование</b></td>
          <td style="vertical-align: top;max-width: 100px;"><b>Строгий контроль <br> за сроками проектирования и строительства</b></td>
        </tr>
      </table>
      <br>
    </div>

    <div
      id="third-block"
    >
      <div style="max-width: 960px; position: relative; margin: auto;">
        Мы работаем в этой области более 12 лет. Обеспечиваем точное, качественное и быстрое выполение поставленных задач. У нас есть необходимые лицензии для организации каналов связи на территории России, квалифицированные специалисты для быстрого проектирования и монтажа линий связи. Кроме того, у нас есть собственная служба поддержки для быстрого решения  возникающих проблем. У нас всегда есть полный комплект запасных частей для оперативного ремонта.
      </div>
    </div>
    <br>
    <div
      id="four-block"
    >
      <div class="title-block">
        ⦾ Область применения
      </div>

      <div
        style="display: inline-flex;">
        <div style="width: 396px; margin: 15px;">
          Радиорелейные линии связи могут применяться в различных областях для решения задач высокоскоростного интернета, когда нет возможности оперативно проложить кабель. Ввод в эксплуатацию пролётов радиорелейной линии занимает от трех рабочих дней. Мы можем взять на себя необходимые согласования, получения частот, монтаж, настройка, запуск. Вы получаете в нужной точке линии связи порт SFP.
        </div>
        <div style="width: 396px; margin: 15px;">
          <b>Радиорелейные линии могут использоваться:</b>
          <ul style="position: relative; right: 25px;">
            <li>Для подключения коттеджных поселков</li>
            <li>Для подключения удаленных предприятий</li>
            <li>Для организации многопролетных линий связи</li>
            <li>Для организации высоконадежных резервных каналов</li>
          </ul>
        </div>
        <div style="width: 396px; margin: 15px;">
          Наши линии связи могут использоваться как в ваших бизнес-приложениях, так и для организации доступа в интернет. <br>
          При необходимости вы можете выкупить наше оборудование. Мы можем предоставить рассрочку или лизинг. Стоимость выкупа зависит от срока действия договора и обсуждается индивидуально.
        </div>
      </div>
    </div>

    <div
      id="five-block"
    >
      <div class="title-block">
        ⦾ Оборудование для построения линии
      </div>
      <br>
      <b>Мы используем оборудование мирового лидера для организации радиорелейной связи - компании Ericsson.</b><br>
      Комплект для организации одного пролета включает в себя оборудование в составе:  <br><br>

        <table id="list">
          <thead>
            <th>
              Описание
            </th>
            <th>
              Кол-во на пролёт
            </th>
          </thead>
          <tbody>
            <tr>
              <td>Ericsson MINI-LINK PT2010 Основной блок РРЛ</td>
              <td>2</td>
            </tr>
            <tr>
              <td>2F LC-LC SM RRUS02, RRUS11 100M / Оптический кабель, 100м.</td>
              <td>2</td>
            </tr>
            <tr>
              <td>PT2010 R1 Basic SW License / Лицензия, базовый функционал РРЛ PT2010</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Cap up to 200 Mbps / Лицензия, емкость РРЛ линии до 200Мбит/с</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Adaptive Modulation / Лицензия, использование адаптивной модуляции</td>
              <td>2</td>
            </tr>
            <tr>
              <td>MINI-LINK PT User Interface Enable / Лицензия, подключения РРЛ оборудования стор. произ.</td>
              <td>2</td>
            </tr>
            <tr>
              <td>OPTICAL TRASCEIVER/TRX SM CPRI DUAL RAT / Оптический SFP-модуль</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Power cable / Кабель питания, 10м</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Earthing Kit, Radio Cable 7.6mm / Комплект для заземления радиокабеля 7.6мм</td>
              <td>2</td>
            </tr>
            <tr>
              <td>ANT2 0.3 (0.6) / Антенна D 0.3м (0.6м) 13 или 23 ГГц</td>
              <td>2</td>
            </tr>

          </tbody>

        </table>
    </div>
    <br>
    
    <div
      id="six-block"
    > 
      <div style="display: inline-flex;">
        <div style="width: 600px;">
          <h2>Наши цены</h2>
          <h1>На аренду линии</h1>
        
        </div>
        <div style="width: 600px; margin-top: 50px;">
          Несмотря на широкие возможности, которые мы предоставляем, мы стараемся удерживать цены на приемлемом уровне. Это получается благодаря прямой работе с дистрибьютором оборудования, автоматизации большинства задач, а также высокопрофессиональному коллективу, которые умеет решать задачи быстро и эффективно.<br>
          Мы предлагаем гибкий подход, который позволяет всем нашим клиентам не переплачивая получить те услуги, которые ему требуются. После завершения договора аренды возможен выкуп оборудования.
        </div>
      </div>
    </div>

    <div
      id="seven-block"
    >
      <div style="width: 396px; margin: 20px 15px;">
        <b>Стоимость монтажа</b> от 40000 рублей.<br>
        <b>Гарантия на оборудование</b> 2 года.<br>
        <b>Базовая стоимость оборудования</b> 650 000руб.    
      </div>

      <div style="width: 396px; margin: 15px;">
        <b>Предоставление в аренду оборудования:</b>
        <ul style="position: relative; right: 25px;">
          <li>
            Аренда ствола 200Мбит/c - 25 000руб/мес.
          </li>
          <li>
            Аренда ствола 400Мбит/c - 35 000руб/мес.
          </li>
        </ul>
      </div>
      <div style="width: 396px; margin: 15px;">
        <b>Предоставление в аренду канала связи</b> 
        <ul style="position: relative; right: 25px;">
          <li>
            Аренда канала 100Мбит/с - 19 000руб/мес
          </li>
          <li>
            Аренда канала 200Мбит/с - 25 000руб/мес
          </li>
          <li>
            Аренда канала 400Мбит/с - 37 000руб/мес
          </li>
        </ul>
      </div>
    </div>

    <div
      id="eight-block"
    >
      <div class="title-block">
        ⦾ Контакты
      </div>
      <br>
      <div style="padding: 0 20px;">
      <img src="../public/img/phone-i.svg" width="40px" style="padding: 15px 15px 0 0 ;">
      <img src="../public/img/phone.svg" width="250px">
      <br>
      <img src="../public/img/mail-i.svg" width="40px" style="padding: 15px 15px 0 0; position: relative; top: 5px;">
      <img src="../public/img/mail.svg" width="250px">
      </div>
    </div>

  </div>

</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: start;
  color: #2c3e50;
  margin: auto;
  max-width: 1280px;
}

.preg {
  height: 140px;
}

#first-block{
  background-image: url('../public/img/foto-1.jpg');
  background-size: cover;
  border: solid 1px #333;
  border-radius: 20px;
  color: #fff;
  height: 400px;
  text-align: left;
  min-width: 1280px;
  max-width: 1280px;
  box-shadow: 0 2px 10px 0px #0005;
}

#first-block h1{
  font-size: 40px;
}

#first-block-description {
  padding: 50px;
  width: 750px;
}

#third-block{
  background-image: url('../public/img/foto-2.jpg');
  background-size: cover;
  border-radius: 20px;
  border: solid 1px #333;
  color: #fff;
  height: fit-content;
  min-width: 1280px;
  max-width: 1280px;
  margin-top: 30px;
  padding: 50px 0px;
  text-align: center;
  box-shadow: 0 2px 10px 0px #0005;
}

#six-block{
  background-image: url('../public/img/foto-3.jpg');
  background-size: cover;
  border-radius: 20px;
  border: solid 1px #333;
  color: #fff;
  height: fit-content;
  min-width: 1280px;
  max-width: 1280px;
  padding: 50px 0px;
  text-align: center;
  box-shadow: 0 2px 10px 0px #0005;
  margin-bottom: 40px;
}

#six-block h1 {
  font-size: 60px;
}

#six-block h2 {
  font-size: 50px;
}

#seven-block{
  display: inline-flex;
}

#eight-block {
}

.title-block{
  margin-top: 30px;
  border-bottom: solid 1px #999;
  color: #406DE4;
  font-size: 35px;
  text-align: start;
  height: 50px;
  font-weight: bold;
}

#list {
  background-color: #fafafa;
  box-shadow: 0 0 10px 0px #0002;
  border: solid 1px #ddd;
  border-radius: 15px;
  margin: 20px 0px;
  padding: 100;
  width: 1280px;
  border-collapse:initial;
}

#list thead{
  
}


#list th{
  border-radius: 0px;
  border-bottom: solid 1px #aaa;
  border-left: solid 0px #eee;
  border-right: solid 0px #eee;
}

#list  tr {
  height: 40px;
  border-top: solid 1px #eee;
  border-left: solid 0px #eee;
  border-right: solid 0px #eee;
  transition: all 0.3s;
}

#list  td {
  padding: 0 20px 0 20px;
  text-align: start;
}

#list  td:nth-child(even) {  
  text-align: end;
}

#list  tr:hover{
  background-color: #eee;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
